import { createSlice } from "@reduxjs/toolkit"
import calculateCountAndPrice from "../../utils/calculateCountAndPrice"

const initialState = {
    items: [],
    totalPrice: 0,
    totalCount: 0,
}
export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addItem: (state, action) => {
            const currentItem = state.items.find((elem) => {
                return elem.id == action.payload.id
            })
            if (currentItem) {
                currentItem.count++
            } else {
                state.items.push({ ...action.payload, count: 1 })
            }
            calculateCountAndPrice(state)
        },
        //функция для очистки корзины
        clearCart: (state, action) => {
            state.items = []
            state.totalPrice = 0
            state.totalCount = 0
        },
        //удаление элемента из корзины
        deleteItemInCart: (state, action) => {
            let id = action.payload.id //находим id
            state.items = state.items.filter((item) => {
                return item.id !== id //вернуть элементы, которые не равны id элементa для удаления
            })
            calculateCountAndPrice(state)

        },
        //уменьшение кол-ва элементов в корзине
        decrementItem: (state, action) => {
            const currentItem = state.items.find((elem) => {
                return elem.id === action.payload.id
            })
            if (currentItem) {
                currentItem.count--
            }
            calculateCountAndPrice(state)
        },
        // const totalPriceByElem = elem.reduce((sum, obj) => {
        //     return sum + obj.price * obj.count
        // }, 0)
    }
})
export const selectCurrentCartItem = (id) => (state) => {
    return state.cart.items.find((elem) => elem.id === id
    )
}


export default cartSlice.reducer;
export const { addItem, clearCart, deleteItemInCart, decrementItem } = cartSlice.actions;

