import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addItem, decrementItem, deleteItemInCart, selectCurrentCartItem } from "../../redux/slices/cartReducer";
import products from '../catalogList/products.json'
import './ScuInBasket.css'
import { Box } from "@mui/material";

export default function ScuInBasket({ elem }) {
    const dispatch = useDispatch()
    const currentItemCart = useSelector(selectCurrentCartItem(elem?.id))

    //если в счетчике каунт меньше 1, удаляем элемент из корзины
    if (currentItemCart?.count < 1) {
        dispatch(deleteItemInCart(elem))
    }

    return (
        <Box className='basket__box'>
            {products.data.map((elem) => {
                return <li className='basket__card'>
                    <img
                        className='basket__card-img'
                        src={elem?.img}
                        alt={elem?.title} />

                    <div className='basket__card-info'>
                        <h2 className='basket__card-title'>{elem?.title}</h2>
                        <p className='basket__card-subtitle'>
                            <span style={{ fontWeight: 700 }}>артикул {elem?.scu}</span>,
                            кратность короба: <span style={{ fontWeight: 700 }}>{elem?.multiplicity} шт.</span>,
                            объем: <span style={{ fontWeight: 700 }}>300 мл</span>
                        </p>
                        <div className='basket__card-subtitle_prices'>
                            <p className='basket__card-subtitle current_price' style={{ color: elem?.current_price_font_color }}>{elem?.current_price} &#8381;</p>
                            {elem?.price_without_discount && <p className='basket__card-subtitle without_discount'>{elem?.price_without_discount} 	&#8381;</p>}
                        </div>
                    </div>
                </li>
                // <li className="products__item-cart">
                //     <img
                //         className="products__foto-cart"
                //         data-action="show"
                //         src={elem.img}
                //     />
                //     <div className="products__container-cart">
                //         <h2 className="products__title-cart">{elem.title}</h2>
                //         <p className="products__subtitle">{elem.current_price} $</p>
                //         <div className="products__quantity-sum">

                //             <div className='counter__cart'>
                //                 <button
                //                     className='count__btn-cart plus'
                //                     type='button'
                //                     onClick={() => dispatch(addItem(elem))}
                //                 >+</button>
                //                 <span className="products__quantity-cart">{elem.count} шт.</span>
                //                 <button
                //                     className='count__btn-cart minus'
                //                     type='button'
                //                     onClick={() => dispatch(decrementItem(elem))}>-</button>
                //             </div>

                //             <p className="products__sum-cart">сумма: {elem.price * elem.count} $</p>
                //         </div>
                //     </div>
                //     <button
                //         className="products__button-cart"
                //         type="button"
                //         onClick={() => dispatch(deleteItemInCart(elem))}
                //     >
                //         х
                //     </button>
                // </li>
            })}
        </Box>


    );
}
