import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import './NavBar.css'
import { leave } from '../../redux/slices/userReducer'
import logo from '../../images/logo.png'
import ModalOrder from '../modals/modalOrder/ModalOrder'
import ModalReturn from '../modals/modalReturn/ModalReturn'
import ModalAuth from '../modals/modalAuth/ModalAuth'
import ModalRegister from '../modals/modalRegister/ModalRegister'


export default function NavBar() {

  const [isLogged, setIsLogged] = useState(false);
  const token = useSelector((state) => state.user.token)
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [openReturnModal, setOpenReturnModal] = useState(false);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);


  const handleOpenOrderModal = () => setOpenOrderModal(true);
  const handleCloseOrderModal = () => setOpenOrderModal(false);

  const handleOpenReturnModal = () => setOpenReturnModal(true);
  const handleCloseReturnModal = () => setOpenReturnModal(false);

  const handleOpenAuthModal = () => setOpenAuthModal(true);
  const handleCloseAuthModal = () => setOpenAuthModal(false);

  const handleOpenRegisterModal = () => setOpenRegisterModal(true);
  const handleCloseRegisterModal = () => setOpenRegisterModal(false);

  const dispatch = useDispatch();

  useEffect(() => {
    token !== '' ? setIsLogged(true) : setIsLogged(false)
  }, [token])



  return (
    <>
      <nav className='navigation'>
        <div className='navigation__list'>
          <Link to='/' className="navigation_link">
            <img src={logo} className='logo' />
          </Link>
          <button className="navigation_btn" onClick={handleOpenOrderModal}>Как заказать</button>
          <button className="navigation_btn" onClick={handleOpenReturnModal}>Возврат</button>
        </div>

        <div className='navigation__list navigation__list_icon'>
          <Link to="/cart" className="navigation_link navigation_link_shop-bag">
            <span class="material-symbols-outlined">shopping_bag</span>
            <span>0 &#8381;</span>
          </Link>
          <Link to="/favorite" className="navigation_link">
            <span class="material-symbols-outlined">favorite</span>
          </Link>
          {!isLogged ? (
            <button className="navigation_btn" onClick={handleOpenAuthModal}>
              <span className="material-symbols-outlined">person</span>
              Вход/регистрация
            </button>
          ) : (
            <>
              <button className="navigation_btn" >
                <span className="material-symbols-outlined">person</span>
              </button>
              <button className="navigation_btn" onClick={() => dispatch(leave())}>
                <span class="material-symbols-outlined">logout</span>
              </button>
            </>
          )}
        </div>
      </nav >
      <ModalRegister open={openRegisterModal} handleClose={handleCloseRegisterModal} handleOpenAuthModal={handleOpenAuthModal} />
      <ModalAuth open={openAuthModal} handleClose={handleCloseAuthModal} handleOpenRegisterModal={handleOpenRegisterModal} />
      <ModalOrder open={openOrderModal} handleClose={handleCloseOrderModal} />
      <ModalReturn open={openReturnModal} handleClose={handleCloseReturnModal} />
    </>

  )
}


