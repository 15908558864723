import React from 'react'
import './Footer.css'

export default function Footer() {
  return (
    <div className='footer'>
      <div class="footer_box footer_box_contacts">
        <a class="footer_link" href="tel:+74953630350">+7 (921) 884-84-91</a>
        <a class="footer_link" href="mailto:welcome@upeco.ru">gs@ask.su</a>
        <p class="footer_typography">время работы ПН-ПТ с 8:30 до 22:00 (Мск)</p>
      </div>
      <div class="footer_box">
        <a class="footer_link footer_link_grey" href="tel:+74953630350">Юридическая информация</a>
        <a class="footer_link footer_link_grey" href="mailto:welcome@upeco.ru">Правила сайта</a>
        <p class="footer_typography footer_link_grey">ООО «АСК Дистрибьюция». Все права защищены</p>
      </div>
      <p class="footer_typography footer_typography_oferta">Предложение не является публичной офертой.
        Окончательная стоимость с учетом бонусов и скидок, а также наличие товара подтверждается продавцом перед оплатой товара.</p>
    </div>
  )
}
