import React from 'react'
import './Header.css'
import NavBar from "../navBar/NavBar"
import SearchSection from '../searchSection/SearchSection.jsx'

export default function Header() {
    return (
        <div className="header">
            <NavBar />
            <SearchSection />
        </div>
    )
}
