import React from 'react'
import foto_1 from '../../images/carusel1.jpg'
import "./Carusel.css"

export default function Carusel() {
    return (
        <div className='carusel_container'>
            <img className='carusel_img' src={foto_1} ></img>
        </div>
    )
}
