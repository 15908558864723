import React, { useState } from 'react'
import Main from '../components/main/Main';
import Header from '../components/header/Header';
import Brands from '../components/brands/Brands';
import Carusel from '../components/carusel/Carusel';
import Category from '../components/category/Category';
import Footer from '../components/footer/Footer';
import ModalInfo from '../components/modals/modalInfo/ModalInfo';

export default function Home() {
    const [openInfoModal, setOpenInfoModal] = useState(true);

    return (
        <>
            <ModalInfo open={openInfoModal} />
            <Header />
            <Carusel />
            <Brands />
            <Category />
            <Main />
            <Footer />
        </>
    )
}
