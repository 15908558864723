import React from 'react'
import './Category.css'
import household from "../../images/household.png"
import cosmetics from "../../images/cosmetics.png"
import reppelents from "../../images/reppelents.png"
import insecticides from "../../images/insecticides.png"
import himiya from "../../images/himiya.png"
import shoesCosmetics from "../../images/shoesCosmetics.png"
import catalog from "../../images/catalog.png"



export default function Category() {

    const categoryList = [
        { title: "Бытовая химия", img: household, className: "category_card_household" },
        { title: "Косметика", img: cosmetics, className: "category_card_cosmetics" },
        { title: "Репелленты", img: reppelents, className: "category_card_reppelents" },
        { title: "Инсектициды", img: insecticides, className: "category_card_insecticides" },
        { title: "Косметика для обуви", img: shoesCosmetics, className: "category_card_shoesCosmetics" },
        { title: "Хозтовары", img: himiya, className: "category_card_himiya" },
        { title: "Каталог", img: catalog, className: "category_card_catalog" },
    ]
    return (
        <div className='category'>
            {categoryList.map(({ title, img, className }) => (
                <div className={`category_card ${className}`}>
                    <p className='category_title'>{title}</p>
                    <img className='category_img' src={img} />
                </div>
            )
            )}
        </div>
    )
}
