import { Box, Modal } from '@mui/material';
import React from 'react'
import '../Modal.css'
import { Warning } from '@mui/icons-material';

export default function ModalInfo({ open }) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: 'none',
    outline: "none",
    boxShadow: 24,
    p: 4,
    borderRadius: '6px',
    maxHeight: "80%",
    overflowY: "scroll",
    fontFamily: "Montserrat",
    fontSize: "14px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description" >
      <Box sx={style}>
        <Warning color='error' />
        <h2 className='modal_main-title'>Сайт находится на техническом обслуживании</h2>
        <p>Пожалуйста, зайдите позже.</p>
      </Box>
    </Modal>
  )
}
