import { Close } from '@mui/icons-material';
import { Box, Modal } from '@mui/material';
import React from 'react'
import '../Modal.css'

export default function ModalOrder({ open, handleClose }) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
    pt: 0,
    borderRadius: '6px',
    maxHeight: "80%",
    overflowY: "scroll",
    fontFamily: "Montserrat",
    fontSize: "14px"
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description" >
      <Box sx={style}>
        <Box className='modal_close'><Close onClick={handleClose} /></Box>
        <h2 className='modal_main-title'>Вы можете оформить заказ несколькими удобными способами:</h2>

        <ul className='modal_ul-list'>
          <li className='modal_list'>
            <h3 className='modal_header'>На сайте</h3>
            <ul className='modal_points'>
              <li>&#8226; Выберите необходимый товар и добавьте его в корзину.
              </li>
              <li>&#8226; Укажите адрес доставки и предпочитаемый способ оплаты.</li>
              <li>&#8226; Нажмите кнопку "Оформить заказ".</li>
            </ul>
            <p className='modal_desc'>Если для оформления заказа потребуется дополнительная информация, мы свяжемся с Вами.</p>
          </li>
          <li className='modal_list'>
            <h3 className='modal_header'>По телефону</h3>
            <ul className='modal_points'>
              <li>Вы также можете сделать заказ, позвонив нам по номеру 8 (921) 884-84-91. Наши менеджеры с радостью помогут Вам оформить заказ.</li>
            </ul>
          </li>
          <p className='modal_desc modal_desc_bold'>Мы стремимся сделать процесс покупки максимально простым и удобным для вас. Если у Вас возникнут любые вопросы, наша команда будет рада оказать Вам всестороннюю поддержку.</p>
        </ul>
      </Box>
    </Modal>
  )
}
