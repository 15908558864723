import React from 'react'
import './SearchSection.css'

export default function SearchSection() {
    const currentUrl = window.location.href;

    return (
        <div className='search-section'>
            <div className='desk_menu'>
                <ul id="desktop-multilevel-menu">
                    <li>
                        {currentUrl == 'http://localhost:3000/' ?
                            <a href="/catalog/" class="root-item">
                                <span class="text_burger">Каталог <span class="text-hidden">товаров</span></span>
                                <span class="material-symbols-outlined burger_icon">menu</span>
                            </a>
                            :
                            <a href="/" class="root-item">
                                <span class="text_burger">Вернуться на главную</span>
                            </a>
                        }
                    </li>
                </ul>
            </div>
            <div className='search_bar'>
                <div className='search_bar_input'>
                    <input id="search-input"
                        placeholder="Искать ..."
                        type="text"
                        name="search-input"
                        value=""
                        autocomplete="off"
                        className="search_bar_form-control" />
                    <span className="material-symbols-outlined search_icon">search</span>
                </div>
            </div>
            <div className='contacts'>
                <a href="tel:" className="u_phone">8 921 888-88-88</a>
                <a href="mailto:gs@ask.su" className="u_mail">gs@ask.su</a>
            </div>
        </div>
    )
}
