import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addItem, decrementItem, deleteItemInCart, selectCurrentCartItem } from '../../redux/slices/cartReducer'
import { deleteItem, pushItem, selectCurrentLikeItem } from '../../redux/slices/favoriteReducer'
import './Card.css'

export default function Card({ elem }) {
  const dispatch = useDispatch()
  const currentItem = useSelector(selectCurrentLikeItem(elem?.id))
  const currentItemCart = useSelector(selectCurrentCartItem(elem?.id))

  if (currentItemCart?.count < 1) {
    dispatch(deleteItemInCart(elem))
  }

  return (
    <li className='card'>
      <img
        className='card_foto'
        data-action="show"
        src={elem?.img}
        alt={elem?.title} />
      <div className='card_subtitle_prices'>
        <p className='card_subtitle current_price' style={{ color: elem?.current_price_font_color }}>{elem?.current_price} &#8381;</p>
        {elem?.price_without_discount && <p className='card_subtitle without_discount'>{elem?.price_without_discount} 	&#8381;</p>}
      </div>
      <h2 className='card_title'>{elem?.title}</h2>
      <p className='card_subtitle'>арт. <span style={{ fontWeight: 700 }}>{elem?.scu}</span></p>
      <p className='card_subtitle'>кратность короба: <span style={{ fontWeight: 700 }}>{elem?.multiplicity} шт.</span></p>
      <p className='card_subtitle'>объем: <span style={{ fontWeight: 700 }}>300 мл</span></p>


      <div className='card_button_box'>
        {currentItemCart ? <button className="card_button deleteFromCart" type="button">
          <div className='counter'>
            <button
              className='count minus'
              type='button'
              onClick={() => dispatch(decrementItem(elem))}>–</button>
            <span className='count quantity'>{currentItemCart.count} кор.</span>
            <button
              className='count plus'
              type='button'
              onClick={() => dispatch(addItem(elem))}
            >+</button>
          </div>
        </button>
          : <button className="card_button" type="button" onClick={() => dispatch(addItem(elem))}>
            <span class="material-symbols-outlined">
              shopping_cart
            </span>
          </button>}


        {currentItem ? <button className="card_button" type="button" onClick={() => dispatch(deleteItem(elem))}>
          <span class="material-symbols-outlined" style={{ color: "red" }}>
            favorite
          </span>
        </button> : <button className="card_button" type="button" onClick={() => dispatch(pushItem(elem))}>
          <span class="material-symbols-outlined">
            favorite
          </span>
        </button>}
      </div>
    </li>
  )
}
