import React, { useContext, useEffect, useState } from 'react';
import { CategoryContext } from '../../context/CategoryContext';
import axios from 'axios';
import Sciletons from '../Sciletons';
import products from './products.json'
import './CatalogList.css'
import Cards from '../cards/Cards';
import PriceRangeSlider from '../priceRangeSlider/PriceRangeSlider';
import CheckboxBrands from '../сheckboxBrands/CheckboxBrands';
import CheckboxCategory from '../сheckboxCategory/CheckboxCategory';

export default function CatalogList() {
  const [arr, setArr] = useState([])
  const [count, setCount] = useState(0);
  const { currentCateg, setCurrentCateg } = useContext(CategoryContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setArr(products.data);
  }, [])

  const sciletons = [...new Array(4)].map(() => <Sciletons />);


  return (
    <div className='catalog'>
      <h3 className='catalog__title'>Продукция</h3>

      <div className='catalog__box'>
        <div className='sideBar'>
          <div className='sideBar__box'>
            <PriceRangeSlider />
            <CheckboxBrands />
            <CheckboxCategory />
          </div>
        </div>

        <ul className='catalog__list'>
          {loading ?
            sciletons
            :
            <Cards prod={arr} />
          }
        </ul>
      </div>

    </div>
  )
}
