import React, { useState } from 'react'
import { Close } from '@mui/icons-material';
import { Box, Modal } from '@mui/material';
import '../Modal.css'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { Field, Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { setToken, setUserId } from '../../../redux/slices/userReducer'
import './ModalAuth.css'

export default function ModalAuth({ open, handleClose, handleOpenRegisterModal }) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: 'none',
        boxShadow: 24,
        p: 4,
        pt: 0,
        borderRadius: '6px',
        maxHeight: "80%",
        overflowY: "scroll",
        fontFamily: "Montserrat",
        fontSize: "14px"
    };

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const initialValues = {
        email: '',
        password: ''
    }

    const loginSchema = Yup.object().shape({
        email: Yup.string('Введите корректный email').email('Введите корректный email').required('Обязательное поле для заполнения').min(5, 'Введите не менее 5 символов').max(25, 'Введите не более 25 символов'), //ключ email - это строка, эл/адрес, обязательное поле(не пустое), минималье кол-во и максимальное кол-во символов - эти методы взяты из библиотеки Yup
        password: Yup.string('Введите корректный пароль').min(5, 'Введите не менее 5 символов').max(25, 'Введите не более 25 символов').required('Обязательное поле для заполнения'),
    })

    const onSubmit = (values) => {
        handleLogin(values, {
            onSuccess: (res) => {
                dispatch(setToken(res.data.token))
                dispatch(setUserId(res.data.data._id))
                navigate('/')
            }, onError: (res) => {

            }
        })
    }
    const { mutate: handleLogin } = useMutation({
        mutationFn: (body) => {
            return axios.post('https://api.react-learning.ru/signin', body)
        }
    })

    const [openForgotBox, setOpenForgotBox] = useState(false)

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description" >
            <Box sx={style}>
                <Box className='modal_close'><Close onClick={handleClose} /></Box>
                <h2 className='modal_main-title modal_main-title_auth'>Вход</h2>

                <Formik initialValues={initialValues} validationSchema={loginSchema} onSubmit={onSubmit}>
                    {(formik) => {
                        return (
                            <Form className="form">
                                <Field
                                    className="form__input"
                                    id="input-userEmail"
                                    name="email"
                                    type="email"
                                    placeholder="Введите E-mail"
                                    autoComplete="off"
                                    required
                                />
                                <Field
                                    className="form__input"
                                    id="input-password"
                                    name="password"
                                    type="password"
                                    placeholder="Введите пароль"
                                    required
                                />
                                <p className="form__forgot" onClick={() => setOpenForgotBox((prev) => !prev)}>Забыли пароль?</p>
                                {openForgotBox && <Box>
                                    <p className="form__forgot form__forgot_desc">Восстановить по email</p>
                                </Box>}
                                <button className="form__input form__input_btn" type='submit'>Войти</button>
                                <p className="form__subtitle">
                                    Еще не зарегистрированы?{" "}
                                    <button className="form__register" onClick={() => {
                                        handleOpenRegisterModal()
                                        handleClose()
                                    }}>
                                        Зарегестрироваться
                                    </button>
                                </p>
                            </Form>
                        )
                    }}

                </Formik>
            </Box>
        </Modal>
    )
}