import React from 'react'
import { Close } from '@mui/icons-material';
import { Box, Modal } from '@mui/material';
import '../Modal.css'

export default function ModalReturn({ open, handleClose }) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: 'none',
        boxShadow: 24,
        p: 4,
        pt: 0,
        borderRadius: '6px',
        maxHeight: "80%",
        overflowY: "scroll",
        fontFamily: "Montserrat",
        fontSize: "14px"
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description" >
            <Box sx={style}>
                <Box className='modal_close'><Close onClick={handleClose} /></Box>
                <h2 className='modal_main-title'>Возврат товара</h2>
                <p className='modal_desc'>Cрок возврата товара надлежащего качества составляет 14 дней с момента получения товара.</p>
                <p className='modal_desc'>Причиной для возврата товара со стороны Покупателя может быть следующие:</p>

                <ul className='modal_ul-list'>
                    <li className='modal_list'>
                        <h3 className='modal_header'>1. Возврат качественного товара:</h3>
                        <ul className='modal_points'>
                            <li>&#8226; информация и характеристики о товаре на сайте, а также фото товара на сайте отличаются от полученного товара
                            </li>
                            <li>&#8226; неправильная комплектация заказа, пересорт</li>
                        </ul>
                        <p className='modal_desc'>Товар принимается назад только в полной комплектации, со всеми упаковками и наклейками, в неиспользованном виде.</p>
                        <p className='modal_desc'>Качественные товары, не подлежащие обмену и возврату, указаны в Перечне, утвержденном постановлением Правительства РФ от 19 января 1998 г. № 55.</p>
                    </li>
                    <li className='modal_list'>
                        <h3 className='modal_header'>2. Возврат некачественного товара:</h3>
                        <ul className='modal_points'>
                            <li>&#8226; наличие дефекта/брака в товаре
                                <p className='modal_desc'>При обнаружении дефекта/брака Вам следует написать претензию на электронный адрес @.su и подробно изложить суть претензии.</p>
                                <p className='modal_desc'>По истечении гарантийного срока претензии по качеству товара, в том числе по возврату товара по гарантии, удовлетворению не подлежат.</p>
                            </li>
                            <li>&#8226; брак, возникший в процессе эксплуатации
                                <p className='modal_desc'>В случае если товар был в употреблении, он принимается в момент обращения на проверку подлинности брака. Проверка проводится в срок до 10 календарных дней. В случае подтверждения брака, возврат денежных средств Покупателю осуществляется в соответствии с Законодательством РФ;</p>
                                <p className='modal_desc'>В случае если подлинность брака не подтверждается, денежные средства Покупателю не возвращаются.</p>
                            </li>
                        </ul>
                        <p className='modal_desc modal_desc_bold'>Вам следует написать претензию на электронный адрес @.su и подробно изложить суть претензии.</p>
                    </li>
                </ul>
            </Box>
        </Modal>
    )
}