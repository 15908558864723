import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import '../catalogList/CatalogList.css'
import './CheckboxCategory.css'

const CheckboxCategory = () => {
    const options = [
        { label: 'Бытовая химия', value: 'card_household' },
        { label: 'Косметика', value: 'cosmetics' },
        { label: 'Репелленты', value: 'reppelents' },
        { label: 'Инсектициды', value: 'insecticides' },
        { label: 'Для обуви', value: 'shoesCosmetics' },
        { label: 'Хозтовары', value: 'himiya' },
    ];

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        setSelectedOptions((prev) =>
            prev.includes(value)
                ? prev.filter((option) => option !== value)
                : [...prev, value]
        );
    };

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
            <Typography className='sideBar__title'>Категория</Typography>
            <Box sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridTemplateRows: "1fr",
                gridColumnGap: "5px",
                gridRowGap: "5px",
            }}>
                {options.map((option) => (
                    <Box key={option.value}>
                        <label className='label__brands'>
                            <input
                                className='checkbox__brands'
                                type="checkbox"
                                value={option.value}
                                checked={selectedOptions.includes(option.value)}
                                onChange={handleCheckboxChange}
                            />
                            {option.label}
                        </label>
                    </Box>
                ))}

            </Box>

        </Box>
    );
};

export default CheckboxCategory;