import React, { useState } from 'react';
import { Slider, Typography, Box } from '@mui/material';
import '../catalogList/CatalogList.css'
import './PriceRangeSlider.css'

const PriceRangeSlider = () => {
    const [value, setValue] = useState([20, 80]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
            <Typography className='sideBar__title'>Цена</Typography>
            <div className='price__box'>
                <span className='price'>
                    {value[0]}
                </span>
                -
                <span className='price'>
                    {value[1]}
                </span>
            </div>

            <Slider
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                min={0}
                max={5000}
                color="success"
                size="small"
            />

        </Box>
    );
};

export default PriceRangeSlider;