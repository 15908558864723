import React, { useState } from 'react'
import { Close } from '@mui/icons-material';
import { Box, Modal } from '@mui/material';
import '../Modal.css'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { Field, Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import '../modalAuth/ModalAuth.css'

export default function ModalRegister({ open, handleClose, handleOpenAuthModal }) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: 'none',
        boxShadow: 24,
        p: 4,
        pt: 0,
        borderRadius: '6px',
        maxHeight: "90%",
        overflowY: "scroll",
        fontFamily: "Montserrat",
        fontSize: "14px"
    };

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [isChecked, setIsChecked] = useState(false);

    const handleChange = () => {
        setIsChecked(!isChecked);
    };

    const initialValues = {
        firstName: '',
        lastName: '',
        legalName: '',
        mobile: '',
        email: '',
        activity: '',
        password: '',
        repeatPassword: ''
    }

    const loginSchema = Yup.object().shape({
        // email: Yup.string('Введите корректный email').email('Введите корректный email').required('Обязательное поле для заполнения').min(5, 'Введите не менее 5 символов').max(25, 'Введите не более 25 символов'), //ключ email - это строка, эл/адрес, обязательное поле(не пустое), минималье кол-во и максимальное кол-во символов - эти методы взяты из библиотеки Yup
        // password: Yup.string('Введите корректный пароль').min(5, 'Введите не менее 5 символов').max(25, 'Введите не более 25 символов').required('Обязательное поле для заполнения'),
    })

    const onSubmit = (values) => {
        handleLogin(values, {
            onSuccess: (res) => {

            }, onError: (res) => {

            }
        })
    }
    const { mutate: handleLogin } = useMutation({
        mutationFn: (body) => {
            return axios.post('https://api.react-learning.ru/signin', body)
        }
    })


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-placeholderledby="modal-modal-title"
            aria-describedby="modal-modal-description" >
            <Box sx={style}>
                <Box className='modal_close'><Close onClick={handleClose} /></Box>
                <h2 className='modal_main-title modal_main-title_auth'>Регистрация</h2>

                <Formik initialValues={initialValues} validationSchema={loginSchema} onSubmit={onSubmit}>
                    {(formik) => {
                        return (
                            <Form className="form">
                                <Field
                                    className="form__input"
                                    id="firstName"
                                    name="firstName"
                                    placeholder="Имя"
                                    autoComplete="off"
                                    margin="normal"
                                    required
                                />
                                <Field
                                    className="form__input"
                                    id="lastName"
                                    name="lastName"
                                    placeholder="Фамилия"
                                    autoComplete="off"
                                    margin="normal"
                                    required
                                />
                                <Field
                                    className="form__input"
                                    id="legalName"
                                    name="legalName"
                                    placeholder="Юридическое наименование"
                                    autoComplete="off"
                                    margin="normal"
                                />
                                <Field
                                    className="form__input"
                                    id="mobile"
                                    name="mobile"
                                    type="tel"
                                    placeholder="Телефон контактного лица"
                                    autoComplete="off"
                                    margin="normal"
                                    required
                                />
                                <Field
                                    className="form__input"
                                    id="email"
                                    name="email"
                                    type="email"
                                    placeholder="E-mail"
                                    autoComplete="off"
                                    margin="normal"
                                    required
                                />
                                <Field
                                    className="form__input"
                                    id="activity"
                                    name="activity"
                                    placeholder="Сфера деятельности"
                                    autoComplete="off"
                                    required
                                />
                                <Field
                                    className="form__input"
                                    id="password"
                                    name="password"
                                    type="password"
                                    placeholder="Пароль"
                                    margin="normal"
                                    required
                                />
                                <Field
                                    className="form__input"
                                    id="repeatPassword"
                                    name="repeatPassword"
                                    type="password"
                                    placeholder="Подтверждение пароля"
                                    margin="normal"
                                    required
                                />

                                <Box className="checkbox__box">
                                    <input
                                        className='checkbox__btn'
                                        type="checkbox"
                                        id="checkbox1"
                                        name="checkbox"
                                        checked={isChecked}
                                        onChange={handleChange} />
                                    <label for="checkbox1" className='checkbox__label'>Я ознакомлен(а) и согласен(сна) со всеми
                                        <Link to="/" className='checkbox__link'> Правилами</Link>, размещенными на сайте</label>
                                </Box>

                                <button className="form__input form__input_btn" type='submit'>Зарегестрироваться</button>
                                <button className="form__register" onClick={() => {
                                    handleOpenAuthModal()
                                    handleClose()
                                }}>
                                    Войти
                                </button>
                            </Form>
                        )
                    }}

                </Formik>
            </Box>
        </Modal>
    )
}