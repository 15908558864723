import React from 'react'
import Header from '../components/header/Header'
import Basket from '../components/basket/Basket'

export default function Cart() {

    return (
        <>
            <Header />
            <Basket />
        </>
    )
}
