import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import './Basket.css'
import { Box } from '@mui/material';
import ScuInBasket from '../scuInBasket/ScuInBasket';

export default function Basket() {
    const cartList = useSelector((state) => state.cart.items)
        const totalPrice = useSelector((state) => state.cart.totalPrice)
    const totalCount = useSelector((state) => state.cart.totalCount)

    return (
        <Box className="basket">
<h3 className='basket__title'>Моя корзина</h3>
<Box className="basket__box">
    <Box className="basket__scu">
{cartList?.map((obj)=> {
    return <ScuInBasket elem={obj} key={obj.id} />
})}
    </Box>
</Box>
        </Box>
    )
}

    // return cart?.map((obj) => {
    //     return (
    //         <>
    //              <ScuInCart elem={obj} key={obj.id} />


    //              <div className='cart__pay'>
    //                 <div className='cart__total-info'>
    //                     <p className='cart__total'>Количество позиций: {totalCount} шт.</p>
    //                     <p className='cart__total'>Сумма итого: {Math.round(totalPrice, 2)} $</p>
    //                 </div>
    //                 <button className='cart__checkout' type="button">Оплатить</button>
    //                 <p className="user-form__subtitle">
    //                     Еще не зарегистрированы?{" "}
    //                     <Link to="/register" className="user-form__link">
    //                         Зарегестрироваться
    //                     </Link>
    //                 </p>
    //                 <p className="user-form__subtitle">
    //                     Уже зарегистрированы?{" "}
    //                     <Link to="/login" className="user-form__link">
    //                         Войти
    //                     </Link>
    //                 </p>
    //             </div> 
    //         </>
    //     )

    // })

