import React from 'react'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import CatalogList from '../components/catalogList/CatalogList';

export default function Catalog() {
    return (
        <>
            <Header />
            <CatalogList />
            <Footer />
        </>
    )
}
