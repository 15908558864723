import React from 'react'
import './Brands.css'
import { Tooltip } from "@material-ui/core"
import raptor from "../../images/raptor.png"
import gardex from "../../images/gardex.png"
import salton from "../../images/salton.png"
import expel from "../../images/expel.png"
import cleanHome from "../../images/cleanhome.png"
import mosquitall from "../../images/mosquitall.png"
import dividik from "../../images/dividik.png"

export default function Brands() {
    return (
        <div class="index_brands">
            <ul class="brands_ul">
                <Tooltip title="Средства для защиты от насекомых дома и на открытом воздухе. Лидер рынка инсектицидов в России." interactive TransitionProps={{ style: { fontSize: "12px", maxWidth: '300px' } }}>
                    <img className='brands_img' src={raptor} ></img>
                </Tooltip>
                <Tooltip title="Средства для защиты от насекомых на природе. Лидер рынка репеллентов в России." interactive TransitionProps={{ style: { fontSize: "12px", maxWidth: '300px' } }}>
                    <img className='brands_img' src={gardex} ></img>
                </Tooltip>
                <Tooltip title="Средства и аксессуары для всестороннего ухода за обувью. Лидер рынка по уходу за обувью в России." interactive TransitionProps={{ style: { fontSize: "12px", maxWidth: '300px' } }}>
                    <img className='brands_img' src={salton} ></img>
                </Tooltip>
                <Tooltip title="Высококачественные и безопасные средства бытовой химии." interactive TransitionProps={{ style: { fontSize: "12px", maxWidth: '300px' } }}>
                    <img className='brands_img' src={expel} ></img>
                </Tooltip>
                <Tooltip title="Cовременный бренд специализированных и универсальных средств для защиты вашего дома от грызунов и уничтожения вредоносных насекомых." interactive TransitionProps={{ style: { fontSize: "12px", maxWidth: '300px' } }}>
                    <img className='brands_img' src={cleanHome} ></img>
                </Tooltip>
                <Tooltip title="Средства для защиты от насекомых. Более 15 лет опыта производства, использования мирового научного опыта, инновационных химико-биологических разработок." interactive TransitionProps={{ style: { fontSize: "12px", maxWidth: '300px' } }}>
                    <img className='brands_img' src={mosquitall} ></img>
                </Tooltip>
                <Tooltip title="Средства по уходу за обувью. Успешно занимают твердую позицию на рынке более 20 лет." interactive TransitionProps={{ style: { fontSize: "12px", maxWidth: '300px' } }}>
                    <img className='brands_img brands_img_dividik' src={dividik} ></img>
                </Tooltip>

            </ul>
        </div>
    )
}
