import React, { useContext, useEffect, useState } from 'react';
import { CategoryContext } from '../../context/CategoryContext';
import axios from 'axios';
import Sciletons from '../Sciletons';
import products from './products.json'
import './Main.css'
import Cards from '../cards/Cards';

export default function Main() {
  const [arr, setArr] = useState([])
  const [count, setCount] = useState(0);
  const { currentCateg, setCurrentCateg } = useContext(CategoryContext);
  const [loading, setLoading] = useState(false);


  // useEffect(() => {
  //   setLoading(true)
  //   axios.get(`https://`)
  //     .then(json => {
  //       setArr(json.data)
  //       setLoading(false)
  //     })
  // }, [count]);

  // useEffect(() => {
  //   setLoading(true)
  //   axios.get(`https://`)
  //     .then(json => {
  //       setArr(json.data);
  //       setLoading(false)
  //     })
  // }, [currentCateg]);

  useEffect(() => {
    setArr(products.data);
  }, [])

  const sciletons = [...new Array(4)].map(() => <Sciletons />);


  return (
    <div className='products'>
      <h2 className='products__main-title'>Хиты продаж</h2>

      <ul className='products__list'>
        {loading ?
          sciletons
          :
          <Cards prod={arr} />
        }
      </ul>
    </div>
  )
}
