import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import '../catalogList/CatalogList.css'
import './CheckboxBrands.css'

const CheckboxBrands = () => {
    const options = [
        { label: 'Раптор', value: 'raptor' },
        { label: 'Gardex', value: 'gardex' },
        { label: 'Salton', value: 'salton' },
        { label: 'Expel', value: 'expel' },
        { label: 'Чистый дом', value: 'clearHome' },
        { label: 'Mosquitall', value: 'mosquitall' },
        { label: 'Дивидик', value: 'dividik' },
    ];

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        setSelectedOptions((prev) =>
            prev.includes(value)
                ? prev.filter((option) => option !== value)
                : [...prev, value]
        );
    };

    return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
            <Typography className='sideBar__title'>Бренд</Typography>
            <Box sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridTemplateRows: "1fr",
                gridColumnGap: "5px",
                gridRowGap: "5px",
            }}>
                {options.map((option) => (
                    <Box key={option.value}>
                        <label className='label__brands'>
                            <input
                                className='checkbox__brands'
                                type="checkbox"
                                value={option.value}
                                checked={selectedOptions.includes(option.value)}
                                onChange={handleCheckboxChange}
                            />
                            {option.label}
                        </label>
                    </Box>
                ))}

            </Box>

        </Box>
    );
};

export default CheckboxBrands;